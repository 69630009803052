import paymentService from "../../services";
export default {
    data() {
        return {
            hasSuperAdmin: true,
            payment: {},
            transactions: []
        };
    },
    mounted() {
        this.showLoader(true);
        paymentService.getPaymentsDetail(this.$route.params.payment_id).then(response => {
            this.hideLoader();
            this.payment = response.data.data;
            this.transactions = this.payment.transaction;
            console.log(response.data.data);
        })
    }
}