import api from "@/common/services/api";

export default new class {
    baseUrl = "";

    getSelectData(data) {
        return api.instance.get("/get-select-data", {
            params: data
        });
    }
    getPaymentsList(query) {
        return api.instance.get("/payments", {
            params: query
        });
    }
    getPaymentsDetail(id) {
        return api.instance.get("/payments/" + id);
    }

    export(data) {
        return api.instance.get('/payments',{responseType: 'blob', params: data});
    }
};