<app-header>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Payments</h1>
    <a href="#" data-menu="searchMenu" class="header-icon header-icon-2">
        <i class="fa fa-search"></i>
    </a>
    <div class="header-icon header-icon-3 select-filter" style="width: 50px !important;" v-if="hasSuperAdmin">
        <app-select class="border-no custom-dropdown" name="country_id" placeholder="" :options="$store.state.selectData.countries" value-prop="id" v-model="country_id">
            <template v-slot:default="slotProps">
                {{ slotProps.item.emoji }}
            </template>
            <template v-slot:display="slotProps">
                <span class="font-600 me-2">{{ slotProps.item.emoji }}</span>
                {{ slotProps.item.name }}
            </template>
        </app-select>
    </div>
</app-header>

<div class="page-title-clear"></div>
<div class="page-content">
    <div class="text-center" v-if="searchedResultShow">
        <p class="font-15 font-200 my-2 letterSpacing text-secondary text-center text-capitalize">
            Search results for
            <span class="font-500 text-dark">'{{ search }}'</span>
            <button @click="filterClear" class="btn gradient-red btn-xs letterSpacing font-500 ms-3"
                style="padding: 3px 10px !important;">Clear</button>
        </p>
    </div>
    <div class="d-flex justify-content-end" v-if="(payments.length>0)">
        <button class="btn btn-secondary btn-xs mb-2 me-3" @click="export"><i class="fas fa-download"></i> Export</button>
    </div>
    <div class="card card-style" v-if="payments && payments.length > 0">
        <div class="content mb-2 mt-2">
            <div v-for="(record, index) in payments" class="pt-2 mb-2" :class="{'border-top': index}">
                <router-link :to="{'name': 'payment-detail', params: {'payment_id' : record.id}}">
                    <div class="d-flex">
                        <div class="align-self-center">
                            <p class="font-16 color-theme line-height-s mt-1 mb-1 text-capitalize">
                                {{ record.user_name}}
                            </p>
                            <p class="font-12 font-600 line-height-s mt-1 mb-1 text-capitalize">
                                <span class="badge color-light font-12" v-if="record.status == 'approved'" :class="[record.status == 'approved' ? 'gradient-blue':'' ]">{{ record.status }}</span>
                                <span class="badge color-light font-12" v-if="record.status == 'processing'" :class="[record.status == 'processing' ? 'gradient-teal':'' ]">{{ record.status }}</span>
                                <span class="badge color-light font-12" v-if="record.status == 'paid'" :class="[record.status == 'paid' ? 'gradient-green':'' ]">{{ record.status }}</span>
                                <span class="badge color-light font-12" v-if="record.status == 'failed'" :class="[record.status == 'failed' ? 'gradient-red':'' ]">{{ record.status }}</span>
                            </p>
                        </div>
                        <div class="ms-auto ps-3 align-items-end">
                            <p class="font-15 mb-0 text-end color-theme">{{record.currency_symbol}} {{record.payable_amount}}</p>
                            <p class="font-10 mb-n2 text-end">{{ localDate(record.created_at) }}</p>
                        </div>
                        <div class="d-flex ps-2 align-items-center">
                            <i class="fa fa-chevron-right"></i>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
    <div class="mt-5 text-center" v-if="!(payments.length > 0)">
        <i class="fas fa-search font-20 text-secondary mb-3"></i>
        <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
            No Payment Found
        </p>
    </div>
</div>
<div id="searchMenu" class="menu menu-box-bottom rounded-m" data-menu-height="300" data-menu-effect="menu-over">
    <div class="menu-title p-3 pb-2">
        <h2 class="letterSpacing font-17 font-200">Search By</h2>
        <a href="#" class="close-menu" ref="closeBtn"><i class="fa fa-times-circle"></i></a>
    </div>
    <div class="card-style shadow-0 rounded-0 mb-0">
        <div class="input-style has-borders">
            <input class="form-control font-15 font-400 letterSpacing" type="text" placeholder="Search " v-model="search">
            <em></em>
            <i class="disabled invalid"></i>
            <i class="disabled valid"></i>
        </div>
    </div>
    <div class="content mt-0 d-flex justify-content-center">
        <button @click="filterUpdate" :disabled="!search" class="close-menu btn btn-l btn-ripple me-1 primary-light-bg rounded-s letterSpacing">
            Search
        </button>
        <button @click="filterClear" :disabled="!search" class="close-menu btn btn-l btn-ripple primary-alt-light-bg rounded-s letterSpacing">
            Clear
        </button>
    </div>
</div>
<app-footer />