<app-header menu="no">
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Payment Detail</h1>
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </a>
    <div class="header-icon header-icon-3" v-if="(Object.keys(payment).length > 0)">
        <p class="font-15 font-600 mb-0 text-capitalize">
            <span class="" v-if="payment.status == 'approved'" :class="[payment.status == 'approved' ? 'color-blue-dark':'' ]">{{ payment.status }}</span>
            <span class="" v-if="payment.status == 'processing'" :class="[payment.status == 'processing' ? 'color-grey-dark':'' ]">{{ payment.status }}</span>
            <span class="" v-if="payment.status == 'paid'" :class="[payment.status == 'paid' ? 'color-green-dark':'' ]">{{ payment.status }}</span>
            <span class="" v-if="payment.status == 'failed'" :class="[payment.status == 'failed' ? 'color-red-dark':'' ]">{{ payment.status }}</span>
        </p>
    </div>
</app-header>

<div class="page-title-clear"></div>
<div class="page-content">
    <div class="content clearfix mb-2 mt-1">
        <div class="text-secondary float-end font-10 me-3">
            {{localDate(payment.created_at)}}
        </div>
        <p class="font-15 font-800 text-capitalize ms-3">{{ payment.user_name ?  payment.user_name : ''}}</p>
    </div>
    <!-- payment Card -->
    <div class="card card-style" v-if="(Object.keys(payment).length > 0)">
        <div class="content mb-2 mt-2">
            <div class="d-flex">
                <div class="align-self-center ">
                    <p class="mb-2 font-16 color-theme">Ticket Id</p>
                    <p class="mb-2 font-16 color-theme">Requested Amount</p>
                    <p class="mb-2 font-16 color-theme">TDS Amount</p>
                    <p class="mb-2 font-16 color-theme">Payable Amount</p>
                </div>
                <div class="ms-auto align-items-center">
                    <router-link v-if="hasPermission('ticket_read')" :to="{name : 'ticket-detail' ,params :{id:payment.ticket_id} }" class="font-13 scale-box badge gradient-blue mb-2">#{{payment.ticket_unique_id}}</router-link>
                    <p v-if="!hasPermission('ticket_read')" class="font-13 scale-box badge gradient-blue mb-2">{{payment.ticket_id}}</p>
                    <p class="font-15 mb-2 color-theme">{{payment.currency_symbol}} {{payment.requested_amount}}</p>
                    <p class="font-15 mb-2 color-theme">{{payment.currency_symbol}} {{payment.tds_amount}}
                        <span class="">({{payment.tds_percentage}}%)</span>
                    </p>
                    <p class="font-15 mb-2 color-theme">{{payment.currency_symbol}} {{payment.payable_amount}}</p>
                </div>
            </div>
        </div>
    </div>

    <!-- Transaction card -->
    <p class="ms-4 mb-2 font-17 font-600 text-capitalize">Transactions</p>
    <div class="card card-style" v-if="transactions.length>0">
        <div class="content mb-2 mt-2">
            <div v-for="(record, index) in transactions" class="pt-2 mb-2 d-flex" :class="{'border-top': index}">
                <div class="align-self-center">
                    <span class="font-15 font-600 color-highlight line-height-s mt-1 mb-1 text-capitalize">
                        {{ record.transaction_id ?  record.transaction_id : 'Transaction Id'}}
                    </span>
                    <p class="font-12 font-600 line-height-s mt-1 mb-1 text-capitalize">
                        <span class="badge color-light font-12" v-if="record.status == 'failed'" :class="[record.status == 'failed' ? 'gradient-red':'' ]">{{ record.status }}</span>
                        <span class="badge color-light font-12" v-if="record.status == 'completed'" :class="[record.status == 'completed' ? 'gradient-green':'' ]">{{ record.status }}</span>
                        <span class="badge color-light font-12" v-if="record.status == 'processing'" :class="[record.status == 'processing' ? 'gradient-teal':'' ]">{{ record.status }}</span>
                    </p>
                </div>
                <div class="ms-auto align-self-center me-3">
                    <p class="font-15 text-end color-theme mb-0">{{payment.currency_symbol}} {{record.amount}}</p>
                    <span>{{localDate(record.created_at)}}</span>
                </div>
            </div>
        </div>
    </div>

    <div v-if="!transactions.length && !payment">
        <p class="font-15 p-5 text-center">No record found.</p>
    </div>
</div>
<app-footer />